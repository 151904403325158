<template>
  <div>

  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from "vuex";
import {ZoomMtg} from '@zoomus/websdk';
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      joinData: null,
      timer:null,
      sessionError:true,
    }
  },
  computed: {
    ...mapGetters({
      callInfo: 'getCallInfo',
      profile: 'getUserInfo'
    })
  },
  created() {
    ZoomMtg.setZoomJSLib('https://source.zoom.us/2.4.0/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    ZoomMtg.i18n.load('en-US');
    ZoomMtg.i18n.reload('en-US');
  },
  mounted() {
    ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
      console.log("inMeetingServiceListener onUserJoin", data);
    });
    ZoomMtg.inMeetingServiceListener('onMeetingUserLeave',(data) => {
      console.log(data)
    })

    this.$nextTick().then(() => {
      if (this.callInfo != null){
        this.startSession();
      }
    }).then(() => {
     this.timer =  setTimeout(() => {
        if (this.sessionError){
          this.$router.push({name:'sessions'})
        }else{
          clearTimeout(this.timer)
        }
      },10000)
    })
  },
  methods: {
    startSession() {
      try {
        document.getElementById("zmmtg-root").style.display = "block";
        ZoomMtg.init({
          screenShare: false,
          disableRecord: true,
          disableInvite: true,
          leaveUrl: 'https://app.simiaroom.com',
          debug: false,
          success: (success) => {
            this.sessionError = false
            this.joinData = {
              zak: this.callInfo.zak,
              passWord: this.callInfo.password,
              meetingNumber: this.callInfo.roomNumber,
              userName: this.profile.full_name,
              sdkKey: 'RsnL1sRxVMNNXLgwTVGsADEc9vgssGuoBKGf',
              signature: this.callInfo.webjwt,
              success: (success) => {
                this.sessionError = false
                clearTimeout(this.timer)
              },
              error: (error) => {

              }
            };
            ZoomMtg.join(this.joinData);
          },
          error: (error) => {
            EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.videoCall.initError'));
            this.$router.push({name: 'sessions'})
          }
        });
      } catch (er) {
        EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.videoCall.initError'));
        this.$router.push({name: 'sessions'})
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    document.getElementById("zmmtg-root").style.display = "none";
  }
}
</script>

<style scoped>

</style>